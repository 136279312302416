/* eslint-disable */
import React from "react";
import {
    FaClipboardList,
    FaDonate,
    FaFutbol,
    FaInfoCircle,
    FaSwatchbook,
    FaShoppingCart,
    FaUser,
    FaUsers,
    FaCalendarCheck,
    FaRegChartBar,
    FaChalkboard,
    FaHistory,
    FaPercentage,
    FaMoneyCheckAlt,
    FaBoxOpen,
    FaChevronDown,
    FaHandHoldingWater,
    FaCalendarAlt,
    FaHandHoldingUsd,
    FaCalculator,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../../Providers/UserProvider";
const Sidebar = () => {
    const { user } = useUserContext();
    // console.log('user', user)
    return (
        <div
            id="kt_aside"
            className="aside aside-default aside-hoverable"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="false"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >
            <div className="aside-menu flex-column-fluid">
                <div
                    className="menu menu-sub-indention menu-column menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-active-bg menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 my-5 mt-lg-2 mb-lg-0"
                    id="kt_aside_menu"
                    data-kt-menu="true"
                >
                    <div
                        className="hover-scroll-y me-lg-n5 pe-lg-5"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: true, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="20px"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    >
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/arbitraje">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaChalkboard />
                                    </span>
                                </span>
                                <span className="menu-title">Escaner CCA</span>
                            </NavLink>
                        </div>
                        <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaCalculator />
                                    </span>
                                </span>
                                <span className="menu-title">Calculadora</span>
                                <span className="menu-arrow"></span>
                            </span>
                            <div className="menu-sub menu-sub-accordion">
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/bonos">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Bonos</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        {user.frqpoint == 0 && user.statrefresh == 1 && user.plus == 1 ? (
                        //user.statrefresh == 1 ? (
                            <div className="menu-item">
                                <NavLink className="menu-link" to="/eventos">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <FaCalendarAlt />
                                        </span>
                                    </span>
                                    <span className="menu-title">Calendario Deportivo</span>
                                </NavLink>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/nomenclaturas">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaInfoCircle />
                                    </span>
                                </span>
                                <span className="menu-title">Nomenclaturas</span>
                            </NavLink>
                        </div>
                        {/*<div className="menu-item">
                            <NavLink className="menu-link" to="/clones">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaSwatchbook />
                                    </span>
                                </span>
                                <span className="menu-title">Clones</span>
                            </NavLink>
                        </div>*/}
                        {user.frqpoint == 0 && user.statrefresh == 1 && user.plus == 1 ? (
                            <div className="menu-item">
                                <NavLink className="menu-link" to="/administrativo">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <FaBoxOpen />
                                        </span>
                                    </span>
                                    <span className="menu-title">Panel Administrativo</span>
                                </NavLink>
                            </div>
                        ) : (
                            <></>
                        )}
                        {user.frqpoint != 0 ? (
                            <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <FaFutbol />
                                        </span>
                                    </span>
                                    <span className="menu-title">Jugadas</span>
                                    <span className="menu-arrow"></span>
                                </span>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/jugadas-surebets">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Mis Surebets</span>
                                        </NavLink>
                                    </div>
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/jugadas-apuestas">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Mis Apuestas</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {user.frqpoint != 0 ? (
                            <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <FaRegChartBar />
                                        </span>
                                    </span>
                                    <span className="menu-title">Reportes</span>
                                    <span className="menu-arrow"></span>
                                </span>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/reporte-surebets">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Surebets (+/-)</span>
                                        </NavLink>
                                    </div>
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/reporte-apuestas">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Apuestas (+/-)</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {user.frqpoint != 0 ? (
                            <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <FaDonate />
                                        </span>
                                    </span>
                                    <span className="menu-title">Operaciones</span>
                                    <span className="menu-arrow"></span>
                                </span>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/movimientos">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Saldos en Casas y Movimientos</span>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/bankdiario">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Resumen del Dia</span>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink className="menu-link" to="/combinadas">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Combinadas</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {/* <div className="menu-item">
                            <NavLink className="menu-link" to="/caja-cuenta">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaBoxOpen />
                                    </span>
                                </span>
                                <span className="menu-title">Caja Diaria</span>
                            </NavLink>
                        </div>
                        <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaClipboardList />
                                    </span>
                                </span>
                                <span className="menu-title">Cuentas</span>
                                <span className="menu-arrow"></span>
                            </span>
                            <div className="menu-sub menu-sub-accordion">
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/bancarias">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Bancarias</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/casas">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Casas</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>                        
                        <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaMoneyCheckAlt />
                                    </span>
                                </span>
                                <span className="menu-title">Saldos en Cuentas</span>
                                <span className="menu-arrow"></span>
                            </span>
                            <div className="menu-sub menu-sub-accordion">
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/montos">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Registro de Saldo</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/reporte-montos">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Reporte</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>                        
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/confirmados">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaCalendarCheck />
                                    </span>
                                </span>
                                <span className="menu-title">Retiros por confirmar</span>
                            </NavLink>
                        </div>
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/consolidado">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaUsers />
                                    </span>
                                </span>
                                <span className="menu-title">Capital Global</span>
                            </NavLink>
                        </div> */}

                        <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                            <span className="menu-link">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaHistory />
                                    </span>
                                </span>
                                <span className="menu-title">Historial</span>
                                <span className="menu-arrow"></span>
                            </span>
                            <div className="menu-sub menu-sub-accordion">
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/historial-conexiones">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Conexiones</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/historial-planes">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Planes</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        {user.frqpoint == 0 ? (
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/planes">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaShoppingCart />
                                    </span>
                                </span>
                                <span className="menu-title">Planes</span>
                            </NavLink>
                        </div>): (<></>)}
                        {/* <div className="menu-item">
                            <NavLink className="menu-link" to="/promociones">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaPercentage />
                                    </span>
                                </span>
                                <span className="menu-title">Promociones</span>
                            </NavLink>
                        </div> */}
                        {/* <div className="menu-item">
                            <NavLink className="menu-link" to="/gastos">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaHandHoldingWater />
                                    </span>
                                </span>
                                <span className="menu-title">Gastos</span>
                            </NavLink>
                        </div>
                        <div className="menu-item">
                            <NavLink className="menu-link" to="/utilidades">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <FaHandHoldingUsd />
                                    </span>
                                </span>
                                <span className="menu-title">Utilidades</span>
                            </NavLink>
                        </div> */}
                    </div>
                    <div className="message_sidebar text-center flex-column justify-content-center align-items-center">
                        <span className="fs-7">Deslizar</span>
                        <FaChevronDown size={15}></FaChevronDown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
