/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useUserContext } from "../../Providers/UserProvider";
import { useForm } from "react-hook-form";
import { FaAngleDown } from "react-icons/fa";
import FiltrosService from "../../api/services/filtrosService";
import "./filtros.scss";
const Filtro = ({ type }) => {
    const { filtrosLive, filtrosPrematch, setFiltrosLive, setFiltrosPrematch } = useUserContext();
    const { user } = useUserContext();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const [items, setItems] = useState([]);
    const [itemsD, setItemsD] = useState([]);
    const [showClones, setShowClones] = useState(false)
    const filtrosCambios = async (data) => {
        if (user.statrefresh == 0) {
            $('#ModalArquiriPlan').modal("show");
            return
        }
        let body = {}
        if (type == "live") {
            //trayendo datos del localstorage
            const datos = JSON.parse(localStorage.filtrosLive)
            datos.deportes = itemsD;
            datos.casas = items;
            localStorage.filtrosLive = JSON.stringify(datos);
            setFiltrosLive((previousState) => ({
                ...previousState,
                casas: items,
                deportes: itemsD
            }));

            body = {
                ...filtrosLive,
                type: type
            }

        } else {
            //trayendo datos del localstorage
            const datos = JSON.parse(localStorage.filtrosPrematch)
            datos.deportes = itemsD;
            datos.casas = items;
            localStorage.filtrosPrematch = JSON.stringify(datos);
            setFiltrosPrematch((previousState) => ({
                ...previousState,
                casas: items,
                deportes: itemsD
            }));

            body = {
                ...filtrosPrematch,
                type: type
            }
        }
        
        try {
            // console.log(body);
            const resp = await FiltrosService.setFiltros(body);
            // console.log(resp);
        } catch (error) {
            console.log(error);
        } 

    };
    const [position, setPosition] = useState(true);
    const [positionD, setPositionD] = useState(true);

    const changeMode = () => {
        setPosition(!position);
        if (!position) {
            handleCheck("check");
        } else {
            handleCheck("uncheck");
        }
    };
    const changeModeD = () => {
        setPositionD(!positionD);
        if (!positionD) {
            handleCheckD("check");
        } else {
            handleCheckD("uncheck");
        }
    };
    let userTheme = localStorage.getItem("userTheme");

    useEffect(() => {
        let filtrosData = []
        if (type == "live") {
            filtrosData = JSON.parse(localStorage.getItem("filtrosLive"));
        } else {
            filtrosData = JSON.parse(localStorage.getItem("filtrosPrematch"));
        }
        let checkC = discheck(filtrosData.casas);
        let checkD = discheck(filtrosData.deportes);
        setPosition(checkC);
        setPositionD(checkD);
        setItems(filtrosData.casas);
        setItemsD(filtrosData.deportes);

    }, [type]);
    useEffect(() => {
        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            let box = document.getElementById('content_clon_'+element.id);
        }
    }, [items]);
    const handleClick = (index) => {
        const curr = items;
        curr[index - 1].checked = !curr[index - 1].checked;
        let valCheck = discheck(curr);
        setPosition(valCheck);
        setItems([...curr]);
    };
    const discheck = (data) => {
        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].checked == false) return false;
        }
        return true;
    };
    const handleCheck = (val) => {
        setPosition(!position);
        if (["check", "uncheck"].includes(val)) {
            const updateChecked = val === "check";
            setItems((prev) => [
                ...prev.map(({ checked, ...rest }) => ({
                    ...rest,
                    checked: updateChecked,
                })),
            ]);
        }
    };
    const handleClickD = (index) => {
        const curr = itemsD;
        curr[index - 1].checked = !curr[index - 1].checked;
        let valCheck = discheck(curr);
        setPositionD(valCheck);
        setItemsD([...curr]);
    };
    const handleCheckD = (val) => {
        setPositionD(!positionD);
        if (["check", "uncheck"].includes(val)) {
            const updateChecked = val === "check";
            setItemsD((prev) => [
                ...prev.map(({ checked, ...rest }) => ({
                    ...rest,
                    checked: updateChecked,
                })),
            ]);
        }
    };
    const toggleClon = (id) => {
        var card_clon = document.getElementById("content_clon_" + id);
        card_clon.classList.toggle("show_clon");
    }
    const selectClon = (casa, clon, casaid, clonid) => {
        let data = items
        let datos = {}
        if (type == "live") {
            datos = JSON.parse(localStorage.getItem("filtrosLive"));
        } else {
            datos = JSON.parse(localStorage.getItem("filtrosPrematch"));
        }
        data.map((valuecasa) => {
            if (valuecasa.casa == casa) {
                valuecasa.casa = clon;
                valuecasa.clones[clonid - 1].casa = casa
            }
            return valuecasa;
        });
        datos.casas = data
        if (type == "live") {
            localStorage.filtrosLive = JSON.stringify(datos);
        } else {
            localStorage.filtrosPrematch = JSON.stringify(datos);
        }
        setItems([...data])
        var card_clon = document.getElementById("content_clon_" + casaid);
        card_clon.classList.add("show_clon");
    }
    const hideClons = (e) => {
        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            let box = document.getElementById('content_clon_'+element.id);            
            if (box != null && !box.classList.contains('show_clon')) {
                if (!box.contains(e.target)) {
                    box.classList.add("show_clon");
                }
            }
        }
    }

    var urlImg = window.location.origin + "/assets/bullbet/adquiere-escaner.jpg";

    const adquiereEscaner= {
        backgroundImage: `url(${urlImg})`,
        height: '500px',
        width: '500px !important'
    };

    return (
        <div className="d-flex justify-content-end rounded-top">
            <button type="button" className="btn btn-filtro btn-sm py-2 text-uppercase" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Filtros
            </button>
            <div className="modal fade modal-lg" id="exampleModal" onMouseUp={(e) => hideClons(e)} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal_filtros modal-dialog-centered">
                    <div className="modal-content position-relative">                        
                        <div className="modal-header py-2">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Filtros Scanner - {type == "live" ? 'Live' : 'Prematch'}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-6 py-4 d-flex" >
                            <form name="formFiltroLive" onSubmit={handleSubmit(filtrosCambios)}>
                                <div className="card px-4">
                                    <div className="mb-4">
                                        <header className="text-uppercase fw-bolder fs-14 mb-4 d-flex justify-content-between">
                                            <span>Deportes</span>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label htmlFor="" className="me-4">
                                                    Todos
                                                </label>
                                                <div
                                                    className="button_toggle position-relative w-40px h-20px bg-gray-400 cursor-pointer"
                                                    style={{
                                                        backgroundColor: positionD ? (userTheme == "dark" ? "#33b5ff" : "#000") : "#e3e0e0",
                                                    }}
                                                    onClick={() => changeModeD()}
                                                >
                                                    <button
                                                        style={{ left: positionD ? "22px" : "5px" }}
                                                        className=" position-absolute"
                                                    ></button>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="row d-flex justify-content-start">
                                            {itemsD.map((deporte) => {
                                                // const verfCasaFil = localStorage.filtrosCca.includes(
                                                //   casa.casa
                                                // );
                                                return (
                                                    <div className="col-3 " key={deporte.value}>
                                                        <div className="form-check pb-xl-2 ">
                                                            <input
                                                                className="form-check-input checks_filtros cursor-pointer"
                                                                type="checkbox"
                                                                id={deporte.value}
                                                                name="deporte"
                                                                defaultValue={deporte.value}
                                                                {...register("deportes")}
                                                                checked={deporte.checked}
                                                                onChange={() => {
                                                                    handleClickD(deporte.id);
                                                                }}
                                                            />
                                                            <label className="form-check-label cursor-pointer" htmlFor={deporte.value}>
                                                                {deporte.deporte}
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div>
                                        <header className="text-uppercase fw-bolder fs-14 mb-4 d-flex justify-content-between">
                                            <span>Casas</span>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label htmlFor="" className="me-4">
                                                    Todos
                                                </label>
                                                <div
                                                    className="button_toggle position-relative w-40px h-20px bg-gray-400 cursor-pointer"
                                                    style={{
                                                        backgroundColor: position ? (userTheme == "dark" ? "#33b5ff" : "#000") : "#e3e0e0",
                                                    }}
                                                    onClick={() => changeMode()}
                                                >
                                                    <button style={{ left: position ? "22px" : "5px" }} className=" position-absolute"></button>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="row d-flex justify-content-start">
                                            {items.map((casa) => {
                                                return (
                                                    <div className="col-3" key={casa.value}>
                                                        <div className="form-check pb-xl-2 position-relative">
                                                            <input
                                                                className="form-check-input checks_filtros "
                                                                type="checkbox"
                                                                id={casa.value}
                                                                name="casas"
                                                                defaultValue={casa.value}
                                                                {...register("casas")}
                                                                checked={casa.checked}
                                                                onChange={() => {
                                                                    handleClick(casa.id);
                                                                }}
                                                            />
                                                            <div className="d-flex gap-2 w-100">
                                                                <label id={"casa_" + casa.id} className="form-check-label " htmlFor={casa.value}>
                                                                    {casa.casa}
                                                                </label>
                                                                <span className="form-check-label">
                                                                    {casa.clones.length > 0 && <div className="icon_show cursor-pointer position-relative">
                                                                        <FaAngleDown size={15} onClick={() => toggleClon(casa.id)}></FaAngleDown>
                                                                        <div id={"content_clon_" + casa.id} className="content_clones  py-2 show_clon">
                                                                            <div className="d-flex flex-column gap-1 ">
                                                                                {casa.clones.map((clon) => {
                                                                                    return (
                                                                                        <span onClick={() => selectClon(casa.casa, clon.casa, casa.id, clon.id)} id={"item_clon_" + casa.id + clon.id} className="item_clon ps-4" key={clon.id}>
                                                                                            {clon.casa}
                                                                                        </span>
                                                                                    )
                                                                                })}
                                                                            </div>

                                                                        </div>
                                                                    </div>}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-center gap-2 mt-4">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                                        Cerrar
                                    </button>
                                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                                            Filtrar
                                        </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade modal-md" id="ModalArquiriPlan" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal_filtros modal-dialog-centered">
                    <div className="modal-content position-relative" style={adquiereEscaner}>
                        <div className="modal-header py-2" style={{border: 'none'}}>
                            <button type="button" className="btn-close mt-2" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filtro;
