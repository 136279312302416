/* eslint-disable */

import UseService from '../UseServices'

const FiltrosService = {
  async setFiltros(data) {
    var resp = await UseService.post('filtros/set', data)
    return resp
  },
}
export default FiltrosService
