/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";

const Bonos = () => {
    const [stake1, setStake1] = useState(100);
    const [stake2, setStake2] = useState(100);
    const [cuota1, setCuota1] = useState(3);
    const [cuota2, setCuota2] = useState(2);
    const [ganancia1, setGanancia1] = useState(100);
    const [ganancia2, setGanancia2] = useState(100);

    const handleMontos = (e) => {
        const { name, value } = e.target;
        if (name === "stake") {
            handleStake(value);
            setStake1(value);
        } else if (name === "cuota1") {
            handleCuota1(value);
            setCuota1(value);
        } else if (name === "cuota2") {
            handleCuota2(value);
            setCuota2(value);
        }
    }

    const handleStake = (value) => {
        if(value.length > 0){
            let total1 = (parseFloat(value) * cuota1).toFixed(2);
            let ganaaux = (parseFloat(total1) - parseFloat(value)).toFixed(2);
            let stakeaux2 = (parseFloat(ganaaux) / cuota2).toFixed(2);
            let gana1 = (parseFloat(ganaaux) - parseFloat(stakeaux2)).toFixed(2);

            setGanancia1(gana1);
            setGanancia2(gana1);
            setStake2(stakeaux2);
        }
    }
    
    const handleCuota1 = (value) => {
        if(value.length > 0){
            let total1 = (parseFloat(stake1) * value).toFixed(2);
            let ganaaux = (parseFloat(total1) - parseFloat(stake1)).toFixed(2);
            let stakeaux2 = (parseFloat(ganaaux) / cuota2).toFixed(2);
            let gana1 = (parseFloat(ganaaux) - parseFloat(stakeaux2)).toFixed(2);

            setGanancia1(gana1);
            setGanancia2(gana1);
            setStake2(stakeaux2);
        }
    }
    
    const handleCuota2 = (value) => {
        if(value.length > 0){
            let total1 = (parseFloat(stake1) * cuota1).toFixed(2);
            let ganaaux = (parseFloat(total1) - parseFloat(stake1)).toFixed(2);
            let stakeaux2 = (parseFloat(ganaaux) / value).toFixed(2);
            let gana1 = (parseFloat(ganaaux) - parseFloat(stakeaux2)).toFixed(2);

            setGanancia1(gana1);
            setGanancia2(gana1);
            setStake2(stakeaux2);
        }
    }

    return (
        <Main>
            <div className="container">
                <div className="overlay card-rounded bg-active-dark">
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow mt-5">
                            <div className="card-header border-0 p-6">
                                <h3>Calculadora de bonos</h3>
                            </div>
                            <div className="card-body p-5">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="border border-secondary text-center">
                                            <tr className="fw-bold">
                                                <th>TIPO</th>
                                                <th>STAKE</th>
                                                <th>CUOTA</th>
                                                <th>GANANCIA</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center border border-secondary">
                                            <tr className="border border-secondary">
                                                <td className="align-middle">BONOS</td>
                                                <td className="align-middle p-1">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            aria-label="bono"
                                                            autoComplete="off"
                                                            name="stake"
                                                            value={stake1}
                                                            style={{
                                                                width: "8rem",
                                                            }}
                                                            onChange={(e) => {
                                                                handleMontos(e);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="align-middle p-1">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            aria-label="cuota1"
                                                            autoComplete="off"
                                                            name="cuota1"
                                                            value={cuota1}
                                                            style={{
                                                                width: "8rem",
                                                            }}
                                                            onChange={(e) => {
                                                                handleMontos(e);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="align-middle">{ganancia1}</td>
                                            </tr>
                                            <tr className="border border-secondary">
                                                <td className="align-middle">DINERO EN CASA</td>
                                                <td className="align-middle">{stake2}</td>
                                                <td className="align-middle p-1">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            aria-label="cuota2"
                                                            autoComplete="off"
                                                            name="cuota2"
                                                            value={cuota2}
                                                            style={{
                                                                width: "8rem",
                                                            }}
                                                            onChange={(e) => {
                                                                handleMontos(e);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="align-middle">{ganancia2}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Bonos;
