/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useUserContext } from "../../Providers/UserProvider";
import "./scanner.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaArrowUp, FaArrowDown, FaArrowRight, FaWindowRestore, FaCalculator } from "react-icons/fa";
const tooltip = (
    <Tooltip id="tooltip">
        <strong>Abrir en ventana flotante</strong>
    </Tooltip>
);
// import Filtro from '../filtro/Filtro'


function Scanner({ actSurebet, dataFilter, type, setLiveSure, mood, showFilter }) {
    // console.log('actSurebet',actSurebet)
    const [surebets, setSuerebets] = useState([]);
    const { user, filtrosLive, filtrosPrematch } = useUserContext();
    const [msgGame, setMsgGame] = useState("Hola");
    const tooltipGame = (
        <Tooltip id="tooltipGame">
            <strong>{msgGame}</strong>
        </Tooltip>
    );
    useEffect(() => {
        if (showFilter) {
            setSuerebets(actSurebet);
        } else {
            setSuerebets([]);
        }
    }, [actSurebet, showFilter]);

    const filterTime = (time) => {
        let color = "pink";
        let tiempo = time.substring(time.length, 2).trim();
        let numero = time.substring(0, time.indexOf(" "));
        numero = parseInt(numero);
        if (tiempo == "few") {
            color = "yellow";
        }
        if (tiempo == "sec") {
            if (numero >= 1 && numero <= 30) {
                color = "yellow";
            }
            if (numero >= 31 && numero <= 59) {
                color = "blue";
            }
        }
        return color;
    };
    // console.log("filtrosLive en escaner", filtrosLive);
    const filterName = (name) => {
        if (name != "") {
            if (type == 2) {
                if (filtrosLive.casas.length != 0) {
                    const filterSurebet = filtrosLive.casas.filter((e) => e.value == name);
                    if (filterSurebet.length != 0) {
                        return filterSurebet[0].casa;
                    } else {
                        return false;
                    }
                }
            } else {
                if (filtrosPrematch.casas.length != 0) {
                    const filterSurebet = filtrosPrematch.casas.filter((e) => e.value == name);
                    if (filterSurebet.length != 0) {
                        return filterSurebet[0].casa;
                    } else {
                        return false;
                    }
                }
            }
        }
        return false

    };
    const iconSport = (sport) => {
        const nameWhioutSpace = sport.replace(/\s/g, "-").replace(":", "-").replace(".", "-");
        // const nameCleaned = nameWhioutSpace.replace(':', '-')
        const nameComplete = "icon1_" + nameWhioutSpace;
        // console.log('nameComplete',nameComplete)
        return nameComplete;
    };
    const nameSport = (sport) => {
        const nameWhioutSpace = sport.replace(/\s/g, "-").replace(":", "-").replace(".", "-");
        return nameWhioutSpace;
    };
    return (
        <div className="w-100 rounded p-0">
            <div>
                {mood ? (
                    <div className="card row w-100 px-0 m-0 ">
                        {surebets.map((surebet, index) => (
                            surebet.length > 0 ?
                                <div className="col-12 " key={index}>
                                    <div className="row bg_surebet_gray" onClick={() => setLiveSure(surebet)}>
                                        <div
                                            className={`col-2 d-flex font-semibold fs-7 px-0 text-${filterTime(
                                                surebet[0].arbgenerationtime
                                            )}-cca justify-content-center bg_surebet-${filterTime(surebet[0].arbgenerationtime)}_light`}
                                        >
                                            <span
                                                className={`w-100 h-100 text-center py-1 position-relative text-black d-flex justify-content-center font-semibold align-items-center surebet_percent-${filterTime(
                                                    surebet[0].arbgenerationtime
                                                )}`}
                                            >
                                                {surebet[0].arbprofit}%
                                            </span>
                                            <span
                                                className={`w-100 h-100 text-center py-1 position-relative text-black d-flex justify-content-center fw-normal align-items-center surebet_time-${filterTime(
                                                    surebet[0].arbgenerationtime
                                                )}`}
                                            >
                                                {surebet[0].arbgenerationtime}
                                            </span>
                                        </div>
                                        <div className="col-1 d-flex gap-2 py-1  align-content-center text-white my-auto cursor-pointer  px-1">
                                            <div className="d-flex fw-bold fs-7 w-100">
                                            {(surebet[0].middle).length > 0 ? 
                                                <div className="bg_surebet-middle h-auto px-1 text-black">{surebet[0].middle}</div>
                                            :<div></div>}
                                                
                                                <div className="px-2">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltipGame">
                                                                <strong>{nameSport(surebet[0].sportname)}</strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="h-100"
                                                            src={"/assets/icons/" + iconSport(surebet[0].sportname) + ".png"}
                                                            alt="icon_sport"
                                                        />
                                                    </OverlayTrigger>
                                                </div>

                                                {/* <span className="px-2">{iconSport(surebet[0].sportname)}</span> */}
                                                {/* <span className="text-uppercase">{surebet[0].sportname}</span> */}
                                            </div>
                                        </div>
                                        <div className="col-5 d-flex gap-2 px-4 py-1 align-content-center text-white my-auto cursor-pointer px-2  h-100">
                                            <div className="fs-6 text-truncate w-100 h-100 w-100">{surebet[0].leaguename}</div>
                                        </div>
                                        <div className="col-3 cursor-pointer d-flex align-items-center">
                                            <span className="fw-bold fs-6 w-100 text-white">{surebet[0].fulltime}</span>
                                        </div>
                                        <div className="col-1 py-1 d-flex flex-column align-items-end cursor-pointer">
                                            <div className="d-flex align-items-end gap-2  justify-content-center my-auto text-white ">
                                                {/* <div className="h-100 fs-7">{surebet[0].arbgenerationtime}</div> */}
                                                <div>
                                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                                        <button className=" btn btn-outline btn-outline-warning btn-sm p-0 px-1">
                                                            <FaCalculator></FaCalculator>
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg_second_row py-2">
                                        <div className="col-2 d-flex justify-content-center align-items-start flex-column text-center text-truncate">
                                            <div className="fw-bold fs-7">
                                                {filterName(surebet[0].bookmaker) != false ? filterName(surebet[0].bookmaker) : ""}
                                                <span className="mx-2">-</span>
                                                {filterName(surebet[1].bookmaker) != false ? filterName(surebet[1].bookmaker) : ""}
                                            </div>
                                        </div>
                                        <div className="col-6 fs-7 text-truncate py-0">
                                            {surebet[0].eventname}-{surebet[1].eventname}
                                        </div>
                                        <div className="col-2 d-flex justify-content-start text-center align-items-center py-0 text-break text_league">
                                            <div className="w-100 fs-7  text-truncate text-start">
                                                {surebet[0].arboutcome} - {surebet[1].arboutcome}
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center align-items-center p-0 m-0">
                                            <div className="d-flex justify-content-center align-items-center gap-2 flex-wrap me-2">
                                                <div>
                                                    {surebet[0].directionodd === "UP" ? (
                                                        <FaArrowUp className="text-success" />
                                                    ) : (
                                                        <FaArrowDown className="text-danger" />
                                                    )}
                                                </div>
                                                <div className="text_league">{surebet[0].odd}</div>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center gap-2 flex-wrap">
                                                <div>
                                                    {surebet[1].directionodd === "UP" ? (
                                                        <FaArrowUp className="text-success" />
                                                    ) : (
                                                        <FaArrowDown className="text-danger" />
                                                    )}
                                                </div>
                                                <div className="text_league">{surebet[1].odd}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-1 bg_three_row d-flex justify-content-center align-items-center flex-column text-center text-truncate">
                                    <div className="fw-semibold fs-7">
                                        
                                    </div>
                                </div>
                                <div className="col-2 bg_three_row fs-7 text-truncate px-2 py-0"></div>
                                <div className="col-2 bg_three_row d-flex justify-content-center text-center align-items-center p-0 text-break text_league">
                                    
                                </div>
                                <div className="col-1 bg_three_row d-flex justify-content-center align-items-center p-0 m-0">
                                    
                                </div> */}
                                    </div>
                                </div> : <></>
                        ))}
                    </div>
                ) : (
                    <div className="card row w-100 px-0 m-0 ">
                        {surebets.map((surebet, index) => (
                            surebet.length > 0 ?
                                <div className="col-12 " key={index}>
                                    <div className="row bg_surebet_gray ">
                                        {/* Porcentaje */}
                                        <div
                                            className={`col-2 d-flex font-semibold fs-7 text-black justify-content-center align-items-center bg_surebet-${filterTime(
                                                surebet[0].arbgenerationtime
                                            )}`}
                                        >
                                            {surebet[0].arbprofit}%
                                        </div>
                                        {/* Titulo */}
                                        <div
                                            className="col-8 d-flex gap-2 text-uppercase align-content-center fw-bold fs-7 my-auto cursor-pointer"
                                            onClick={() => setLiveSure(surebet)}
                                        >
                                        {(surebet[0].middle).length > 0 ? 
                                            <div className="bg_surebet-middle h-auto px-1 text-black fw-bold">{surebet[0].middle}</div>
                                        :<div></div>}
                                            <span className="text-white">{surebet[0].sportname}</span>
                                            <span className="text-white">{surebet[0].fulltime}</span>
                                        </div>
                                        {/* Tiempo y boton */}
                                        <div className="col-2 d-flex flex-column align-items-end">
                                            <div className="d-flex align-items-end gap-2  justify-content-center my-auto text-white">
                                                <div className="h-100 fs-7">{surebet[0].arbgenerationtime}</div>
                                                <div>
                                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                                        <button
                                                            className="btn btn-outline btn-outline-warning px-1 py-0 h-20px d-flex align-items-center" aria-label="setliveSurebet"
                                                            onClick={() => setLiveSure(surebet)}
                                                        >
                                                            <FaCalculator size={10}></FaCalculator>
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg_second_row">
                                        <div className="col-2 d-flex justify-content-center align-items-center flex-column text-center">
                                            <div className="fw-bold fs-7">
                                                {filterName(surebet[0].bookmaker) != false ? filterName(surebet[0].bookmaker) : ""}
                                                {/* {type == 1 && <div className="col-12 fw-bolder text_league">{surebet[0].eventstarttime}</div>} */}
                                                {/* {filtrosLive.casas[surebet[0].bookmaker] === undefined ? surebet[0].bookmaker : filtrosLive.casas[surebet[0].bookmaker]} */}
                                            </div>
                                            {/* <div className="text_league fst-italic text-truncate w-100">{surebet[0].currentscore}</div> */}
                                        </div>
                                        <div className="col-7 p-0 justify-content-center align-content-center">
                                            <div className="row h-100">
                                                <div className="col-12 d-flex flex-column h-100 justify-content-center align-content-center">
                                                    <div className="text_event fs-7 text-truncate fw-normal">{surebet[0].eventname}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center text-center align-items-center p-0 text-break text_league">
                                            {surebet[0].arboutcome}
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center p-0 m-0">
                                            <div className="d-flex justify-content-center align-items-center gap-2 flex-wrap">
                                                <div>
                                                    {surebet[0].directionodd === "UP" ? (
                                                        <FaArrowUp className="text-success" />
                                                    ) : (
                                                        <FaArrowDown className="text-danger" />
                                                    )}
                                                </div>
                                                <div className="text_league">{surebet[0].odd}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg_three_row ">
                                        <div className="col-2 d-flex flex-column justify-content-center align-items-center text-center">
                                            <div className="fw-bold fs-7 ">
                                                {filterName(surebet[1].bookmaker) != false ? filterName(surebet[1].bookmaker) : ""}
                                                {/*filterName(surebet[1].length > 0 ? surebet[1].bookmaker : "") != false ? filterName(surebet[1].length > 0 ? surebet[1].bookmaker : "") : ""*/}
                                                {/* {type == 1 && <div className="col-12 fw-bolder text_league">{surebet[1].eventstarttime}</div>} */}
                                                {/* {casasArbitraje[surebet[1].bookmaker] === undefined ? surebet[1].bookmaker : casasArbitraje[surebet[1].bookmaker]} */}
                                            </div>
                                            {/* <div className="text_league fst-italic text-truncate w-100">{surebet[1].currentscore}</div> */}
                                        </div>
                                        <div className="col-7 p-0">
                                            <div className="row h-100">
                                                <div className="col-12 d-flex flex-column h-100 justify-content-center align-content-center">
                                                    <div className="text_event fs-7 text-truncate fw-normal">{surebet[1].eventname}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 p-0 d-flex justify-content-center text-center align-items-center text_league">
                                            {surebet[1].arboutcome}
                                        </div>
                                        <div className="col-1  p-0 d-flex justify-content-center align-items-center p-0 m-0">
                                            <div className="d-flex justify-content-center gap-2 flex-wrap align-items-center">
                                                <div>
                                                    {surebet[1].directionodd === "UP" ? (
                                                        <FaArrowUp className="text-success" />
                                                    ) : (
                                                        <FaArrowDown className="text-danger" />
                                                    )}
                                                </div>

                                                <div className="text_league">{surebet[1].odd}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg_four_row">
                                        <div className="col-2"></div>
                                        <div className="col-7 d-flex justify-content-start text-center align-items-center px-0">
                                            <div className="fs-7 text-truncate fw-bold">{surebet[0].leaguename}</div>
                                        </div>
                                        <div className="col-3 bg_score_event d-flex justify-content-center text-center align-items-center text_league">
                                            {type == 1 ? (
                                                <div className="col-12 fw-bolder text_league">{surebet[0].eventstarttime}</div>
                                            ) : (
                                                <div className="text_league text-truncate">{surebet[0].currentscore}</div>
                                            )}
                                        </div>
                                    </div>
                                </div> : <></>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default Scanner;
