/* eslint-disable */
import React, { useContext, useState, useRef, useEffect } from "react";
//DATA INICIAL
var initialState = {
    nombre: "",
    apellido: "",
    correo: "",
    celular: "",
    documento: "",
    avatar: "",
    frqpoint: null,
    pvgxd: null,
    statrefresh: null,
    tipocambio: null,
    token: "",
    stake: 100,
    refreshToken: "",
};
let planInicial = {
    id: null,
    tipo: "",
    precio: null,
};
const casasFiltrosLive = {
    casas: [],
    deportes: [],
    inputs: {
        percentMin: "",
        percentMax: "",
        oddsMin: "",
        oddsMax: "",
    },
    especiales:{}
};
const casasFiltrosPrematch = {
    casas: [],
    deportes: [],
    inputs: {
        percentMin: "",
        percentMax: "",
        oddsMin: "",
        oddsMax: "",
    },
    especiales:{}
};
//INSTANCIANDO EL CONTEXT
const userContext = React.createContext();
// const userToogleContext = React.createContext()

//FUNCIONES SET Y GET DEL LOCALSTORAGE PARA INICIALIZAR DATA
function setLocalStorage(key, value) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        //console.log("error", e);
    }
}

function getLocalStorage(key, initialValue) {
    try {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
}

//EXPORTAMOS EL CONTEXTO COMO useUserContext - lo puedes verificar en Controller/ControlleSesion.jsx
export function useUserContext() {
    return useContext(userContext);
}

//FUNCION DEL PROVIDER
export function UserProvider(props) {
    const [user, setUser] = useState(() => getLocalStorage("userCca", initialState));
    const [dataPlan, setDataPlan] = useState(() => getLocalStorage("filterData", planInicial));
    const [theme, setTheme] = useState("");
    const [filtrosLive, setFiltrosLive] = useState(() => getLocalStorage("filtrosLive", casasFiltrosLive));
    const [filtrosPrematch, setFiltrosPrematch] = useState(() => getLocalStorage("filtrosPrematch", casasFiltrosPrematch));
    // const filtrosLive = useRef({});
    // const filtrosPrematch = useRef({});
    // Verificar TEMA
    const verifyThemeUser = () => {
        if (!localStorage.userTheme) {
            localStorage.userTheme = "light";
            setTheme("light");
            document.querySelector("html").dataset.theme = "light";
        } else if (localStorage.userTheme) {
            setTheme(localStorage.userTheme);
            document.querySelector("html").dataset.theme = localStorage.userTheme;
        }
    };
    //MOUNTED DE USER
    useEffect(() => {
        if (localStorage.getItem("userCca")) {
            setLocalStorage("userCca", user);
        }
    }, [user]);
    //MOUNTED DEL PLANES
    useEffect(() => {
        if (localStorage.getItem("filterData")) {
            setLocalStorage("filterData", dataPlan);
        }
    }, [dataPlan]);

    //MOUNTED DE LOS ESTILOS DE LA PLANTILLA, TEMAS, FILTROS
    useEffect(() => {
        window.KTComponents.init();
        verifyThemeUser();
        // verifyUser();
        // loadFiltros();
    }, []);

    return (
        <userContext.Provider value={{ user, setUser, theme, setTheme, filtrosLive, filtrosPrematch, 
            setFiltrosLive, setFiltrosPrematch, dataPlan, setDataPlan, setLocalStorage }}>
            {props.children}
        </userContext.Provider>
    );
}
