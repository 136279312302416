/* eslint-disable */
import React, { useState, useEffect } from "react";
import EventosService from "../../../api/services/eventosService";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import Accordion from "react-bootstrap/Accordion";
import ReactApexChart from "react-apexcharts";
import moment from "moment/moment";
import "../style.css";
import { useUserContext } from "../../../Providers/UserProvider";
import { FaRegFutbol, FaSearch, FaRegClock } from "react-icons/fa";
const today = new Date();
import { da } from "date-fns/locale";
//data estatica
const horarios = [
    {
        text: "De 00:00 - 12:00 am",
        value: "1",
    },
    {
        text: "De 12:01 am - 12:00 pm",
        value: "2",
    },
];

const GraficosE = () => {
    const { theme } = useUserContext();
    let keysData = [];
    const [datos, setDatos] = useState({
        series: [],
        options: {
            chart: {
                height: 400,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: [
                "#009B1C",
                "#FF3E00",
                "#4BB6A4",
                "#E9A513",
                "#8F502A",
                "#E913E9",
                "#8EA818",
            ],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Eventos",
                align: "left",
                offsetY: 15,
                offsetX: 25,
                style: {
                    fontSize: "12px",
                    color: "#FFFFFF",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                tickAmount: 6,
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [stake, setStake] = useState({
        options: {
            chart: {
                type: "bar",
                height: 450,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                tickAmount: 5,
                title: {
                    text: "",
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
        series: [],
    });
    const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
    const [loading, setLoading] = useState(false);
    const [dataCat, setDataCat] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [eventosFilter, setEventosFilter] = useState([]);
    const [sport, setSport] = useState(0);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/eventos", name: "Eventos", active: false },
    ];

    //TRAER DATA
    const getData = async () => {
        const body = {
            fecha: fecha,
        };
        setLoading(true);
        let arrayCategories = [];
        let arraySeriesSoccer = [];
        let arraySeriesBasket = [];
        let arraySeriesVoleyball = [];
        let arraySeriesIceHockey = [];
        let arraySeriesTenis = [];
        let arraySeriesHandball = [];
        const graficos = await EventosService.getGraficos(body);
        try {
            if (graficos.status) {
                setDataCat(graficos.data.grafica2);
                setDataList(graficos.data.listadoEventos);

                for (
                    let index = 0;
                    index < graficos.data.grafica1.length;
                    index++
                ) {
                    //categorias
                    const element = graficos.data.grafica1[index];
                    arrayCategories.push(element.hora);
                    arraySeriesSoccer.push(element.soccer);
                    arraySeriesBasket.push(element.basketball);
                    arraySeriesVoleyball.push(element.volleyball);
                    arraySeriesIceHockey.push(element.hockey);
                    arraySeriesTenis.push(element.tennis);
                    arraySeriesHandball.push(element.handball);
                }
                setDatos({
                    ...datos,
                    options: {
                        xaxis: {
                            categories: arrayCategories,
                        },
                        responsive: [
                            {
                                breakpoint: 1500,
                                options: {
                                    chart: {
                                        height: 300,
                                        type: "line",
                                        dropShadow: {
                                            enabled: true,
                                            color: "#000",
                                            top: 18,
                                            left: 7,
                                            blur: 10,
                                            opacity: 0.2,
                                        },
                                        toolbar: {
                                            show: false,
                                        },
                                    },
                                    stroke: {
                                        curve: "smooth",
                                        width: 2,
                                    },
                                    legend: {
                                        position: "top",
                                        horizontalAlign: "right",
                                        floating: true,
                                        offsetY: -15,
                                        offsetX: -5,
                                    },
                                },
                            },
                        ],
                    },
                    series: [
                        {
                            name: "Soccer",
                            data: arraySeriesSoccer,
                        },
                        {
                            name: "Basketball",
                            data: arraySeriesBasket,
                        },
                        {
                            name: "Voleyball",
                            data: arraySeriesVoleyball,
                        },
                        {
                            name: "Ice Hockey",
                            data: arraySeriesIceHockey,
                        },
                        {
                            name: "Tennis",
                            data: arraySeriesTenis,
                        },
                        {
                            name: "Handball",
                            data: arraySeriesHandball,
                        },
                    ],
                });
                selectSport(
                    0,
                    "SOCCER",
                    "#009B1C",
                    graficos.data.grafica2[0].eventos,
                    graficos.data.listadoEventos
                );
            }
        } catch (error) {}
        // console.log('graficos', graficos)
        setLoading(false);
    };
    //MOUNTED
    useEffect(() => {
        getData();
        //init tooltip
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFecha(moment(dateStr).format("yyyy-MM-DD"));
                // searchData(moment(dateStr).format("yyyy-MM-DD"))
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    //BUSCAR EVENTOS
    const selectSport = (sportSelect, deporte, color, data, datalist) => {
        try {
            setSport(sportSelect);
            let eventosF = [];
            let arrayCategories = [];
            let arraySerie = [];

            for (let index = 0; index < data.length; index++) {
                //categorias
                const element = data[index].hora;
                arrayCategories.push(element);
                //soccer
                const element1 = data[index].cant;
                arraySerie.push(element1);
            }
            eventosF = datalist.filter((e) => e.deporte == deporte);
            setEventosFilter(eventosF);
            setStake({
                ...stake,
                options: {
                    colors: [color],
                    xaxis: {
                        categories: arrayCategories,
                    },
                    responsive: [
                        {
                            breakpoint: 1500,
                            options: {
                                chart: {
                                    height: 300,
                                },
                            },
                        },
                    ],
                },
                series: [
                    {
                        name: deporte,
                        data: arraySerie,
                    },
                ],
            });
        } catch (error) {}
    };
    return (
        <div className="mw-100 ">
            <div
                className={`overlay card-rounded bg-active-dark ${
                    loading ? "overlay-block" : ""
                }`}
            >
                <div className="overlay-wrapper">
                    <div className="card">
                        <div className="card-header border-0 px-6 pt-2">
                            <div className="card-toolbar w-100">
                                <div className="col-12 d-flex justify-content-end align-items-center gap-2 px-4">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span>Buscar: </span>
                                        <input
                                            className="form-control form-control-solid"
                                            placeholder="Fecha"
                                            id="kt_datepicker_1"
                                        />
                                    </div>
                                    <button
                                        onClick={() => getData()}
                                        className="btn btn-primary btn-icon"
                                    >
                                        <FaSearch></FaSearch>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-1">
                            <ReactApexChart
                                options={datos.options}
                                series={datos.series}
                                type="line"
                                height={300}
                            />
                            <div className="mt-0 border-top">
                                <div className="row mx-0">
                                    <div className="col-12 d-flex flex-column flex-xl-row py-2 px-6 gap-2 gap-xl-0 w-100">
                                        <div className="d-flex gap-5 flex-wrap justify-content-center w-100">
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 0
                                                                ? "#009B1C"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            0,
                                                            "SOCCER",
                                                            "#009B1C",
                                                            dataCat[0].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Soccer
                                                </button>
                                            </div>
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 1
                                                                ? "#FF3E00"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            1,
                                                            "BASKETBALL",
                                                            "#FF3E00",
                                                            dataCat[1].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Basketball
                                                </button>
                                            </div>
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 2
                                                                ? "#4BB6A4"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            2,
                                                            "VOLLEYBALL",
                                                            "#4BB6A4",
                                                            dataCat[2].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Volleyball
                                                </button>
                                            </div>
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 3
                                                                ? "#E9A513"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            3,
                                                            "ICE HOCKEY",
                                                            "#E9A513",
                                                            dataCat[3].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Ice Hockey
                                                </button>
                                            </div>
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 4
                                                                ? "#8F502A"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            4,
                                                            "TENNIS",
                                                            "#8F502A",
                                                            dataCat[4].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Tennis
                                                </button>
                                            </div>
                                            <div className="py-4 d-flex justify-content-center align-items-center">
                                                <button
                                                    className={`btn btn-evento px-3 py-1`}
                                                    style={{
                                                        backgroundColor:
                                                            sport == 5
                                                                ? "#E913E9"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        selectSport(
                                                            5,
                                                            "HANDBALL",
                                                            "#E913E9",
                                                            dataCat[5].eventos,
                                                            dataList
                                                        )
                                                    }
                                                >
                                                    Handball
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <ReactApexChart
                                        options={stake.options}
                                        series={stake.series}
                                        type="bar"
                                        height={300}
                                    />
                                </div>
                                <div className="row mx-0 border-top">
                                    <div className="col-12 pt-4 pb-3 text-center">
                                        <h4>Lista de eventos</h4>    
                                    </div>
                                    <div className="py-5">
                                        {eventosFilter.length > 0 ? (
                                            <Accordion alwaysOpen>
                                                {eventosFilter.map(
                                                    (evento, index) => (
                                                        <Accordion.Item
                                                            eventKey={
                                                                sport.toString() +
                                                                index.toString()
                                                            }
                                                            key={index}
                                                        >
                                                            <Accordion.Header>
                                                                <div className="w-100 fw-bolder">
                                                                    <img
                                                                        src={
                                                                            evento.imgleague
                                                                                ? evento.imgleague
                                                                                : "https://bit.ly/45vyuAu"
                                                                        }
                                                                        alt="liga"
                                                                        width={
                                                                            18
                                                                        }
                                                                        className="me-2"
                                                                    />
                                                                    {evento.country + evento.fullleague}
                                                                </div>
                                                            </Accordion.Header>
                                                            {evento.datos.map(
                                                                (
                                                                    datos,
                                                                    index1
                                                                ) => (
                                                                    <Accordion.Body
                                                                        key={
                                                                            index1
                                                                        }
                                                                    >
                                                                        <div className="d-flex gap-3 flex-column flex-sm-row justify-content-between">
                                                                            <div className="px-4 d-flex border-end border-2 align-items-center justify-content-center">
                                                                                <FaRegClock className="me-1"></FaRegClock>
                                                                                {
                                                                                    datos.hora
                                                                                }
                                                                            </div>
                                                                            <div className="d-flex flex px-4 gap-1 w-100 align-items-center justify-content-center justify-content-sm-start">
                                                                                <img
                                                                                    src={
                                                                                        datos.imghome
                                                                                            ? datos.imghome
                                                                                            : "https://bit.ly/45vyuAu"
                                                                                    }
                                                                                    alt="liga"
                                                                                    width={
                                                                                        18
                                                                                    }
                                                                                    className="me-2"
                                                                                />
                                                                                <span>
                                                                                    {
                                                                                        datos.local
                                                                                    }
                                                                                </span>
                                                                                <span>
                                                                                    {" "}
                                                                                    -{" "}
                                                                                </span>
                                                                                <span>
                                                                                    {
                                                                                        datos.visita
                                                                                    }
                                                                                </span>
                                                                                <img
                                                                                    src={
                                                                                        datos.imgaway
                                                                                            ? datos.imgaway
                                                                                            : "https://bit.ly/45vyuAu"
                                                                                    }
                                                                                    alt="liga"
                                                                                    width={
                                                                                        18
                                                                                    }
                                                                                    className="ms-2"
                                                                                />
                                                                            </div>
                                                                            <div className="d-flex flex px-4 gap-1 align-items-center justify-content-center justify-content-sm-start">
                                                                                <div className="px-4 d-flex border-start border-2 align-items-center justify-content-center">
                                                                                    {/*datos.real ==
                                                                                    "SCHEDULED"
                                                                                        ? "PROGRAMADA"
                                                                                        : datos.real ==
                                                                                          "POSTPONED"
                                                                                        ? "POSPUESTO"
                                                                                        : datos.real ==
                                                                                          "FINISHED"
                                                                                        ? "FINALIZADO"
                                                                                : "JUGANDO"*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Body>
                                                                )
                                                            )}
                                                        </Accordion.Item>
                                                    )
                                                )}
                                            </Accordion>
                                        ) : (
                                            <div className="p-20 d-flex align-items-center justify-content-center flex-column gap-4">
                                                <FaRegFutbol
                                                    size={40}
                                                ></FaRegFutbol>
                                                <span className="fw-bolder">
                                                    No se encontraron datos
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="overlay-event card-rounded bg-black bg-opacity-50">
                        <div className="d-flex flex-column align-items-center mt-10">
                            <div
                                className="spinner-border text-white"
                                role="status"
                            ></div>
                            <span>Buscando graficos...</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default GraficosE;
