/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import { useUserContext } from "../../Providers/UserProvider";
import cards from "../../Assets/cards.json";
import "./nomenclatura.css";
import Main from "../../layout/main";
const Nomenclaturas = () => {
    const { user } = useUserContext();
    //hooks
    const [items, setItems] = useState(cards);
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/nomenclaturas", name: "Nomenclaturas", active: false },
    ];
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-column flex-md-row">
                            {/*<ul className="nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6">
                                {items.map((card) => {
                                    return (
                                        <li className="nav-item item_nom w-md-200px me-0" key={card.id}>
                                            <a
                                                className={card.id == 1 ? "nav-link active p-5 text-center" : "nav-link p-5 text-center"}
                                                data-bs-toggle="tab"
                                                href={"#kt_vtab_pane_" + card.id}
                                            >
                                                <strong>{card.title}</strong>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>*/}
                            <div className="row row-cols-1 row-cols-md-2 g-4" id="myTabContent">
                                {items.map((card) => {
                                    // console.log(card);
                                    return (
                                        <div className="col">
                                            <div className="card card-bordered shadow-sm">
                                                <div className="card-header card_nom_header" style={{minHeight:"60px"}}>
                                                    <h5 className="card-title text-uppercase fw-bold">{card.title}</h5>
                                                </div>
                                                <div className="card-body row" style={{padding: "1.25rem 1.25rem"}}>
                                                    {card.content.map((cont, index) => {
                                                        return (
                                                            <div key={index} className="col-12 col-md-6">
                                                                <div className="card card-bordered mb-5 shadow-sm">
                                                                    <div className="card-header card_nom_header justify-content-start align-content-center">
                                                                        <span className="badge badge-circle badge-lg me-2" style={{ backgroundColor: cont.bg }}></span>
                                                                        <h3 className="card-title">{cont.subtitle}</h3>
                                                                    </div>
                                                                    <div className="card-body p-5">{cont.description}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Nomenclaturas;
