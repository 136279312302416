/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
const Breadcrumbs = ({ crumbs }) => {
    // Don't render a single breadcrumb.
    if (crumbs.length <= 1) {
        return null;
    }
    return (
        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
            <h1 className="text-dark fw-bold my-1 fs-2">{crumbs[crumbs.length - 1].name} </h1>
            {/*<ul className="breadcrumb fw-semibold fs-base my-1">
                {crumbs.map(({ name, path, active }, key) =>
                    active === false ? (
                        <li className={key + 1 === crumbs.length ? "breadcrumb-item text-dark" : "breadcrumb-item text-muted "} key={key}>
                            {name}
                        </li>
                    ) : (
                        <li key={key} className="breadcrumb-item text-muted ">
                            <Link className="text-muted text-hover-primary" to={path}>
                                {name}
                            </Link>
                        </li>
                    )
                )}
            </ul>*/}
        </div>
    );
};
export default Breadcrumbs;
