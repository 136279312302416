/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SurebetService from "../../../api/services/surebetService";
import ProfileService from "../../../api/services/profileService";
import { useUserContext } from "../../../Providers/UserProvider";
import { FaCopy } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";

const tooltipTC = (
    <Tooltip id="tooltipTC">
        <strong>Ver tipo de cambio</strong>
    </Tooltip>
);
// Función principal

// function calculateDatos(actSurebet, bankinicial, setDatCal) {
//   console.log('actSurebet caldatos',actSurebet)
//   return () => {

//   console.log('dataca', datCal)
//   };
// }

const Calculator = () => {
    const [queryParameters] = useSearchParams();
    let actSurebet = queryParameters.get("data");
    actSurebet = actSurebet ? JSON.parse(actSurebet) : [];
    let tc = queryParameters.get("tc");
    let type = queryParameters.get("type");
    let stake = queryParameters.get("stake");
    let userTc = queryParameters.get("userTC");
    // console.log("userTc", userTc);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    // Inicializacion de variables y datos de scaner

    const refreshToken = localStorage.getItem("refreshToken");
    const [bankinicial, setBankinicial] = useState(100);
    const [showTC, setShowTC] = useState(false);
    const [apuestaSelect, setApuestaSelect] = useState(null);
    const [smsCopy, setSmsCopy] = useState("Copiar");
    const { user, filtrosLive, filtrosPrematch } = useUserContext();
    const [percent, setPercent] = useState(actSurebet.length > 0 ? actSurebet[0].arbprofit : 0);
    const [datCal, setDatCal] = useState({
        odd1: null,
        odd2: null,
        apuesta1: null,
        apuesta2: null,
        gana1: null,
        gana2: null,
    });
    const [cambio, setCambio] = useState({
        tc1: null,
        tc2: null,
    });
    const [checkTC, setCheckTC] = useState({
        tc1: true,
        tc2: true,
    });
    const [checkStake, setCheckStake] = useState({
        stake1: false,
        stake2: false,
    });
    const [userData, setuserData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("userCca");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingM, setLoadingM] = useState(false);
    // const calDatos = calculateDatos(actSurebet, bankinicial, setDatCal);
    // Inicializacion de datos en el mounted
    // SI hay data
    const tooltipCopy = (
        <Tooltip id="tooltipCopy">
            <strong>{smsCopy}</strong>
        </Tooltip>
    );
    useEffect(() => {
        tc_moneda();
        if (actSurebet.length > 0) {
            setBankinicial(stake);
            const odd1 = Number(actSurebet[0].odd);
            const odd2 = Number(actSurebet[1].odd);
            const indice = Number((1 / odd1 + 1 / odd2).toFixed(2));
            const apuesta1 = Number((stake / (odd1 * indice)).toFixed(2));
            const apuesta2 = Number((stake / (odd2 * indice)).toFixed(2));
            const gana1 = Number((apuesta1 * actSurebet[0].odd - stake).toFixed(2));
            const gana2 = Number((apuesta2 * actSurebet[1].odd - stake).toFixed(2));
            setDatCal((previousState) => ({
                ...previousState,
                odd1: odd1,
                odd2: odd2,
                apuesta1: apuesta1,
                apuesta2: apuesta2,
                gana1: gana1,
                gana2: gana2,
            }));
            setPercent(actSurebet[0].arbprofit);
        }
        var ele = document.getElementsByName("optionStake");
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
                ele[i].checked = false;
            }
        }
        setApuestaSelect(null);
    }, []);

    useEffect(() => {
        tc_moneda(checkStake);
    }, [userTc]);

    // Si no hay data
    if (!actSurebet) {
        return <></>;
    }
    //TC
    function tc_moneda(checkStake) {
        if (userData) {
            let tipoCambio1 = null;
            let tipoCambio2 = null;
            tipoCambio1 = (parseFloat(userTc) * datCal.apuesta1).toFixed(2);
            tipoCambio2 = (parseFloat(userTc) * datCal.apuesta2).toFixed(2);
            if (checkStake != undefined) {
                if (!checkStake.stake1 && !checkStake.stake2) {
                    setCambio({ tc1: Number(tipoCambio1), tc2: Number(tipoCambio2) });
                }
                if (checkStake.stake1) {
                    setCambio((prevState) => ({ ...prevState, tc2: Number(tipoCambio2) }));
                }
                if (checkStake.stake2) {
                    setCambio((prevState) => ({ ...prevState, tc1: Number(tipoCambio1) }));
                }
            } else {
                setCambio((previousState) => ({
                    ...previousState,
                    tc1: Number(tipoCambio1),
                    tc2: Number(tipoCambio2),
                }));
            }
        }
    }

    // Función de Modificar cuotas y variables
    const handleChangeValuesCal = (e) => {
        const { name, value } = e.target;
        if (name === "odd1") {
            calCuota1(value, datCal, bankinicial, checkStake, setBankinicial);
        } else if (name === "odd2") {
            calCuota2(datCal, value, bankinicial, setDatCal, checkStake, setBankinicial);
        } else if (name === "apuesta1") {
            calApuesta1(datCal, value, setBankinicial, setDatCal, false);
        } else if (name === "apuesta2") {
            calApuesta2(datCal, value, setBankinicial, setDatCal, false);
        } else if (name === "bankinicial") {
            setBankinicial(value);
            calCapital(value, actSurebet, setDatCal, setBankinicial);
        } else if (name === "tc1") {
            calTC1(datCal, value, setBankinicial, setDatCal);
        } else if (name === "tc2") {
            calTC2(datCal, value, setBankinicial, setDatCal);
        }
    };

    //Funciones generales
    function calTC1(datCal, value, setBankinicial, setDatCal) {
        // console.log("value", value);
        setCambio((existingValues) => ({
            ...existingValues,
            tc1: value,
        }));
        let val = parseFloat(value / userTc).toFixed(2);
        // console.log("val", val);
        calApuesta1(datCal, val, setBankinicial, setDatCal, true);
    }
    function calTC2(datCal, value, setBankinicial, setDatCal) {
        setCambio((existingValues) => ({
            ...existingValues,
            tc2: value,
        }));
        let val = parseFloat(value / userTc).toFixed(2);
        calApuesta2(datCal, val, setBankinicial, setDatCal, true);
    }
    //CUOTAS: modifican stake y ganancia
    function calCuota1(value, datCal, bankinicial, checkStake,setBankinicial) {
        let od1 = parseFloat(value);
        let od2 = parseFloat(datCal.odd2);
        let indice = 1 / od1 + 1 / od2;
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        if (checkStake.stake2 && !checkStake.stake1) {
            let apuesta2 = parseFloat(datCal.apuesta2);
            const tot = parseFloat(apuesta2 * od2).toFixed(2);
            let ap1 = value != "" ? parseFloat(tot / od1).toFixed(2) : 0;
            let total = parseFloat(parseFloat(ap1) + parseFloat(apuesta2)).toFixed(2);
            let gana1 = value != "" ? parseFloat(ap1 * od1 - total).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta2 * od2 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd1: value, apuesta1: isNaN(ap1) ? (0).toFixed(2) : ap1, gana1: gana1, gana2: gana2 });
            tipoCambio1 = isNaN(ap1) ? (0).toFixed(2) : (userTc * ap1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: tipoCambio1,
            }));
            setPercent(gana1);
            setBankinicial(total);
        }
        if (checkStake.stake1 && !checkStake.stake2) {
            let apuesta1 = parseFloat(datCal.apuesta1);
            const tot = parseFloat(apuesta1 * od1).toFixed(2);
            let apuesta2 = value != "" ? parseFloat(tot / od2).toFixed(2) : 0;
            let total = parseFloat(parseFloat(apuesta2) + parseFloat(apuesta1)).toFixed(2);
            let gana1 = value != "" ? parseFloat(apuesta2 * od2 - total).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta1 * od1 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd1: value, apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2, gana1: gana1, gana2: gana2 });
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: tipoCambio2,
            }));
            setPercent(gana1);
            setBankinicial(total);
        }
        if (!checkStake.stake1 && !checkStake.stake2) {
            let ap1 = value != "" ? parseFloat(bankinicial / (od1 * indice)).toFixed(2) : parseFloat(0).toFixed(2);
            let apuesta2 = parseFloat(bankinicial / (od2 * indice)).toFixed(2);
            let gana1 = value != "" ? parseFloat(ap1 * od1 - bankinicial).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta2 * od2 - bankinicial).toFixed(2) : 0;
            setDatCal({
                ...datCal,
                odd1: value,
                apuesta1: isNaN(ap1) ? (0).toFixed(2) : ap1,
                apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2,
                gana1: gana1,
                gana2: gana2,
            });
            tipoCambio1 = isNaN(ap1) ? (0).toFixed(2) : (userTc * ap1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            // console.log("tipoCambio1", tipoCambio1);
            // console.log("tipoCambio2", tipoCambio2);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
            setPercent(gana1);
        }
    }
    function calCuota2(datCal, value, bankinicial, setDatCal, checkStake,setBankinicial) {
        const odd1 = parseFloat(datCal.odd1);
        const odd2 = parseFloat(value);
        const indice = 1 / odd1 + 1 / odd2;
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        if (checkStake.stake1 && !checkStake.stake2) {
            const apuesta1 = parseFloat(datCal.apuesta1);
            const tot = parseFloat(apuesta1 * odd1).toFixed(2);
            const apuesta2 = value != "" ? parseFloat(tot / odd2).toFixed(2) : parseFloat(0).toFixed(2);
            let total = parseFloat(parseFloat(apuesta1) + parseFloat(apuesta2)).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta1 * odd1 - total).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta2 * odd2 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd2: value, apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2, gana1: gana1, gana2: gana2 });
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: tipoCambio2,
            }));
            setBankinicial(total);
            setPercent(((gana2 * 100) / total).toFixed(2));
        }
        if (checkStake.stake2 && !checkStake.stake1) {
            const apuesta2 = parseFloat(datCal.apuesta2);
            const tot = parseFloat(apuesta2 * odd2).toFixed(2);
            const apuesta1 = value != "" ? parseFloat(tot / odd1).toFixed(2) : parseFloat(0).toFixed(2);
            let total = parseFloat(parseFloat(apuesta2) + parseFloat(apuesta1)).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta2 * odd2 - total).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta1 * odd1 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd2: value, apuesta1: isNaN(apuesta1) ? (0).toFixed(2) : apuesta1, gana1: gana1, gana2: gana2 });
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: tipoCambio1,
            }));
            setBankinicial(total);
            setPercent(((gana2 * 100) / total).toFixed(2));
        }
        if (!checkStake.stake2 && !checkStake.stake1) {
            const apuesta1 = parseFloat(bankinicial / (odd1 * indice)).toFixed(2);
            const apuesta2 = value != "" ? parseFloat(bankinicial / (odd2 * indice)).toFixed(2) : parseFloat(0).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta1 * odd1 - bankinicial).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta2 * odd2 - bankinicial).toFixed(2) : 0;
            setDatCal({
                ...datCal,
                odd2: value,
                apuesta1: isNaN(apuesta1) ? (0).toFixed(2) : apuesta1,
                apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2,
                gana1: gana1,
                gana2: gana2,
            });
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
            setPercent(gana1);
        }
    }
    //STAKE: modifca el otro stake y ganancias
    function calApuesta1(datCal, value, setBankinicial, setDatCal, val) {
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        const odd1 = datCal.odd1;
        const odd2 = datCal.odd2;
        const apuesta1 = Number(value);
        const total1 = Number((apuesta1 * odd1).toFixed(2));
        const apuesta2 = Number((total1 / odd2).toFixed(2));
        const montoTotal = (apuesta1 + apuesta2).toFixed(2);
        const gana1 = Number((total1 - montoTotal).toFixed(2));
        const total2 = Number((apuesta2 * odd2).toFixed(2));
        const gana2 = Number((total2 - montoTotal).toFixed(2));
        if (checkTC.tc1 && val) {
            tipoCambio2 = (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: Number(tipoCambio2),
            }));
            // setCambio({ ...cambio, tc2: Number(tipoCambio2) });
        } else {
            tipoCambio1 = (userTc * apuesta1).toFixed(2);
            tipoCambio2 = (userTc * apuesta2).toFixed(2);
            setCambio({ tc1: Number(tipoCambio1), tc2: Number(tipoCambio2) });
        }
        // console.log('(userTc * apuesta1).toFixed(2)', (userTc * apuesta1).toFixed(2))
        // console.log('(userTc * apuesta2).toFixed(2)', (userTc * apuesta2).toFixed(2))
        setBankinicial(montoTotal);
        setDatCal({
            ...datCal,
            apuesta1: value,
            apuesta2: apuesta2,
            gana1: gana1,
            gana2: gana2,
        });
        setPercent(((gana1 * 100) / montoTotal).toFixed(2));
    }
    function calApuesta2(datCal, value, setBankinicial, setDatCal, val) {
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        const odd1 = datCal.odd1;
        const odd2 = datCal.odd2;
        const apuesta2 = Number(value);
        const total2 = Number((apuesta2 * odd2).toFixed(2));
        const apuesta1 = Number((total2 / odd1).toFixed(2));
        const montoTotal = Number((apuesta1 + apuesta2).toFixed(2));
        const gana2 = Number((total2 - montoTotal).toFixed(2));
        const total1 = Number((apuesta1 * odd1).toFixed(2));
        const gana1 = Number((total1 - montoTotal).toFixed(2));
        if (checkTC.tc2 && val) {
            tipoCambio1 = (userTc * apuesta1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: Number(tipoCambio1),
            }));
        } else {
            tipoCambio1 = (userTc * apuesta1).toFixed(2);
            tipoCambio2 = (userTc * apuesta2).toFixed(2);
            setCambio({ tc1: Number(tipoCambio1), tc2: Number(tipoCambio2) });
        }
        setBankinicial(montoTotal);
        setDatCal({ ...datCal, apuesta1: apuesta1, apuesta2: value, gana1: gana1, gana2: gana2 });
        setPercent(((gana1 * 100) / montoTotal).toFixed(2));
    }
    //MODIFICAN EL TOTAL
    function calCapital(value, actSurebet, setDatCal, setBankinicial) {
        const bankinicial = Number(value);
        const odd1 = Number(datCal.odd1);
        const odd2 = Number(datCal.odd2);
        const indice = Number((1 / odd1 + 1 / odd2).toFixed(2));
        const apuesta1 = Number((bankinicial / (odd1 * indice)).toFixed(2));
        const apuesta2 = Number((bankinicial / (odd2 * indice)).toFixed(2));
        const gana1 = Number((apuesta1 * odd1 - bankinicial).toFixed(2));
        const gana2 = Number((apuesta2 * odd2 - bankinicial).toFixed(2));
        setDatCal((previousState) => ({
            ...previousState,
            apuesta1: apuesta1,
            apuesta2: apuesta2,
            gana1: gana1,
            gana2: gana2,
        }));
        setPercent(((gana1 * 100) / bankinicial).toFixed(2));
        setBankinicial(value);
    }
    const filterTime = (time) => {
        let color = "pink";
        let tiempo = time.substring(time.length, 2).trim();
        let numero = time.substring(0, time.indexOf(" "));
        numero = parseInt(numero);
        if (tiempo == "few") {
            color = "yellow";
        }
        if (tiempo == "sec") {
            if (numero >= 1 && numero <= 30) {
                color = "yellow";
            }
            if (numero >= 31 && numero <= 59) {
                color = "blue";
            }
        }
        return color;
    };
    const onOptionChange = (e) => {
        setShowTC(e);
        tc_moneda(checkStake);
    };
    const copyLeague = (id) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", document.getElementById("name_league"+id).innerHTML);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        setSmsCopy("¡Copiado!");
        setTimeout(() => {
            setSmsCopy("Copiar");
        }, "2000");
    };
    const filterName = (name) => {
        // console.log("type", type);
        if (type == "live") {
            if (filtrosLive.casas.length != 0) {
                const filterSurebet = filtrosLive.casas.filter((e) => e.value == name);
                if (filterSurebet.length != 0) {
                    return filterSurebet[0].casa;
                } else {
                    return false;
                }
            }
        } else {
            if (filtrosPrematch.casas.length != 0) {
                const filterSurebet = filtrosPrematch.casas.filter((e) => e.value == name);
                if (filterSurebet.length != 0) {
                    return filterSurebet[0].casa;
                } else {
                    return false;
                }
            }
        }
    };
    const splitName1 = (name) => {
        let event = name.split("-")
        return event[0].trim()
    }
    const splitName2 = (name) => {
        let event = name.split("-")
        return event[1].trim()
    }
    //Diseño general
    return (
        <div className="w-100 rounded-bottom shadow">
            <div className="tablero_info rounded-bottom d-flex flex-column">
                <div className="w-100  box_options d-flex my-2">
                    <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                        <input
                            type="checkbox"
                            className="form-check-input border_tc"
                            name="tipo_cambio"
                            id="option-2"
                            value={"tc"}
                            onChange={(e) => onOptionChange(e.target.checked)}
                        />
                        <label htmlFor="option-2">Tipo Cambio</label>
                    </div>
                </div>
                <table className="w-100 rounded-bottom">
                    <tbody className="rounded-bottom">
                        <tr>
                            <td
                                align="center"
                                colSpan={1}
                                className={`info_percent bg_surebet-${actSurebet.length > 0 ? filterTime(actSurebet[0].arbgenerationtime) : "red"
                                    }  text-black fw-normal fs-14 py-2`}
                            >
                                {actSurebet.length > 0 ? actSurebet[0].arbprofit : ""}%
                            </td>
                            <td align="center" colSpan={1} className="info_deporte  bg_surebet_gray text-white fw-normal fs-14 py-2">
                                {actSurebet[0].sportname}
                            </td>
                            {type == "prematch" && (
                                <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">
                                    {actSurebet.length > 0 ? actSurebet[0].eventstarttime : ""}
                                </td>
                            )}
                            {type == "live" && (
                                <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">
                                    {actSurebet.length > 0 ? actSurebet[0].currentscore : ""}
                                </td>
                            )}
                            <td
                                align="center"
                                colSpan={showTC == true ? 3 : 2}
                                className="info_time text-black fw-normal fs-14 py-1  bg-gray_light"
                            >
                                {actSurebet.length > 0 ? actSurebet[0].fulltime : ""}
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                className="info_evento header_event fw-normal fs-14 text-white py-2"
                                colSpan={showTC == true ? 6 : 5}
                            >
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <div id="name_league" className="fw-normal fs-14 ">
                                        {actSurebet.length > 0 ? actSurebet[0].eventname : ""}{" "}
                                    </div>
                                    <OverlayTrigger placement="top" overlay={tooltipCopy}>
                                        <span className="cursor-pointer " onClick={() => copyLeague(1)}>
                                            <FaCopy className="ms-2"></FaCopy>
                                        </span>
                                    </OverlayTrigger>
                                    <div id="name_league1" className="fw-normal fs-14 mx-1">
                                        {actSurebet.length > 0 ? splitName1(actSurebet[0].eventname) : ""}
                                    </div>
                                    <span>-</span>
                                    <div id="name_league2" className="fw-normal fs-14 mx-1">
                                        {actSurebet.length > 0 ? splitName2(actSurebet[0].eventname) : ""}
                                    </div>
                                    <OverlayTrigger placement="top" overlay={tooltipCopy}>
                                        <span className="cursor-pointer " onClick={() => copyLeague(2)}>
                                            <FaCopy className=""></FaCopy>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Casa
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Apuesta
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Cuota
                            </td>
                            {showTC === true && (
                                <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                    TC
                                </td>
                            )}
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Stake
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Ganancia
                            </td>
                        </tr>
                        <tr className="content">
                            <td align="center" className="fw-normal">
                                {actSurebet.length > 0
                                    ? actSurebet[0].bookmaker
                                    : ""}
                            </td>
                            <td align="center" className="fw-normal">
                                {actSurebet[0].arboutcome}
                            </td>
                            <td align="center" className="fw-normal">
                                <div className="px-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="odd1"
                                        value={datCal.odd1 || ""}
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={handleChangeValuesCal}
                                    />
                                </div>
                            </td>
                            {showTC === true && (
                                <td
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc1"
                                        readOnly={!checkTC.tc1}
                                        value={cambio.tc1 || ""}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc1"
                                            name="checktc1"
                                            defaultChecked
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc1: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td align="center" className="fw-normal">
                                <div className="px-5 py-2 w-100 text-center d-flex align-content-center justify-content-center gap-3">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta1"
                                        value={datCal.apuesta1 || ""}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake1"
                                            value="stake1"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake1: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td align="center" className="fw-normal">
                                {datCal.gana1}
                            </td>
                        </tr>
                        <tr>
                            <td align="center" className="fw-normal">
                                {actSurebet.length > 0
                                    ? actSurebet[1].bookmaker
                                    : ""}
                            </td>
                            <td align="center" className="fw-normal">
                                {actSurebet[1].arboutcome}
                            </td>
                            <td align="center" className="fw-normal">
                                <div className="px-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="odd2"
                                        value={datCal.odd2 || ""}
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                </div>
                            </td>
                            {showTC == true && (
                                <td
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc2"
                                        value={cambio.tc2 || ""}
                                        readOnly={!checkTC.tc2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc2"
                                            name="checktc2"
                                            defaultChecked
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc2: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td align="center" className="fw-normal">
                                <div className="px-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota me-2"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta2"
                                        value={datCal.apuesta2 || ""}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake2"
                                            value="stake2"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake2: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td colSpan={2} align="center" className="fw-normal">
                                {datCal.gana2}
                            </td>
                        </tr>
                        <tr className="box_total bg_surebet_gray text-white">
                            <td colSpan={2} align="left"></td>
                            <td align="center" colSpan={showTC == true ? 2 : 1} className="text-uppercase">
                                Total
                            </td>
                            <td className="text-center">
                                <input
                                    className="text-center rounded inputCuota inputCuotaTot"
                                    style={{ maxWidth: "60px" }}
                                    type="text"
                                    autoComplete="off"
                                    name="bankinicial"
                                    value={bankinicial}
                                    // key={bankinicial}
                                    onChange={(e) => {
                                        handleChangeValuesCal(e);
                                    }}
                                />
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default Calculator;
